import { Button, ModalActions, Modal, Img } from '@layerise/design-core';
import {
  IconInfo,
  IconStar,
  IconOnboarding,
  IconShop,
  IconChat,
  IconGauge,
  IconRename,
  IconLocation,
} from '@layerise/design-icon';
import React, { useEffect } from 'react';
import { LearnMore, Content, Body, TitleContainer, Title, ModalHero, TitleIconContainer } from './SharedStyles';
import { NORMAL_MODAL_Z_INDEX, FeatureModalProps } from './constants';
import IconImport from 'components/icons/IconImport';
import IconUpsellArrows from 'components/icons/IconUpsellArrows';
import IconAdvancedComponents from 'components/icons/IconAdvancedComponents';
import IconCustomDomain from 'components/icons/IconCustomDomain';
import IconUpsellWarranty from 'components/icons/IconUpsellWarranty';
import IconUpsellReports from 'components/icons/IconUpsellReports';
import IconUpsellCustomers from 'components/icons/IconUpsellCustomers';
import IconUpsellWorkspaces from 'components/icons/IconUpsellWorkspaces';
import IconTeams from 'components/icons/IconTeams';
import IconUpsellAPI from 'components/icons/IconUpsellAPI';
import IconApps from 'components/icons/IconApps';
import IconUpsellForms from 'components/icons/IconUpsellForms';
import IconUpsellRegistrationScore from 'components/icons/IconUpsellRegistrationScore';
import { chatWidget } from 'lib/chatWidget';
import IconUpsellCustomFont from 'components/icons/IconUpsellCustomFont';
import IconUpsellSerialNumbers from 'components/icons/IconUpsellSerialNumbers';
import IconUpsellUsers from 'components/icons/IconUpsellUsers';

export type ModalVariant =
  | 'teams'
  | 'data_import'
  | 'multiple_workspace'
  | 'shop'
  | 'customer_lists'
  | 'reports'
  | 'sms_campaigns'
  | 'warranty'
  | 'advanced_components'
  | 'rules'
  | 'custom_domain'
  | 'forms'
  | 'feedback'
  | 'api'
  | 'apps'
  | 'conversations'
  | 'registrationScore'
  | 'purchaseIntentScore'
  | 'serialNumber'
  | 'custom_font'
  | 'lite_users'
  | 'diary'
  | 'geo_fencing';

interface UpsellModalProps extends FeatureModalProps {
  variant: ModalVariant;
}

const getModalContent = (variant: ModalVariant) => {
  switch (variant) {
    case 'teams':
      return {
        title: 'Organize Layerise with teams',
        description:
          'Teams are an excellent way for groups of members in your organization to work together in Layerise. You can assign conversations or warranty requests to a team, rather than an individual member.',
        icon: <IconTeams />,
        url: '/help/automation/teams',
        bgColor: '#140068',
        coverImg: 'team-cover.png',
      };
    case 'data_import':
      return {
        title: 'Data import',
        description:
          'Supporting multiple white-label products? Save time and repetitive tasks with Data import. Move data like products and their related Assistants to other workspaces. ',
        icon: <IconImport width={16} height={16} viewBox="0 0 16 16" />,
        url: '/help/my-account/data-import',
        bgColor: '#140068',
        coverImg: 'workspace_import-cover.png',
      };
    case 'multiple_workspace':
      return {
        title: 'Support multiple-brands with workspaces',
        description:
          'Have multiple brands in your organization? With multiple workspaces, your customers will see and engage with each brand. Set cross-workspace user permissions and switch easily between the workspaces. ',
        icon: <IconUpsellWorkspaces />,
        url: '/help/my-account/multiple-workspaces',
        bgColor: '#052bfc',
        coverImg: 'workspaces-cover.png',
      };
    case 'shop':
      return {
        title: 'Setup product upsell with Shop',
        description:
          'Add shop links directly in the main navigation on each product home to upsell relevant products and services. Add your discount codes and campaign identifiers to track the performance and improve conversion.  ',
        icon: <IconShop width={'16'} height={'16'} />,
        url: '/help/marketing/shop',
        bgColor: '#052bfc',
        coverImg: 'shop-cover.png',
      };
    case 'customer_lists':
      return {
        title: 'Organize and segment with Customer Lists',
        description:
          'Build smart customer lists to reach in campaigns, automate processes or organize your customers. Build your lists manually or based on automation filtering.',
        icon: <IconUpsellCustomers />,
        url: '/help/automation/customer-lists',
        bgColor: '#052bfc',
        coverImg: 'customer_lists-cover.png',
      };
    case 'reports':
      return {
        title: 'Get essential customer insights with Reports',
        description:
          'Get smarter insights to optimize your marketing campaigns and improve your ROI. Unlock customer-centric measurements and discover trends, new markets, and demographics.',
        icon: <IconUpsellReports />,
        url: '/help/insights/reports',
        bgColor: '#052bfc',
        coverImg: 'reports-cover.png',
      };
    case 'sms_campaigns':
      return {
        title: 'Launch successful campaigns with SMS',
        description:
          "Send SMS campaigns to your customers when they register their product, when you have company news, or even better, when they're ready to buy with an exclusive discount.",
        icon: <IconOnboarding width={'16'} height={'16'} />,
        url: '/help/marketing/sms-campaigns',
        bgColor: '#7a52d6',
        coverImg: 'sms_campaigns-cover.png',
      };
    case 'warranty':
      return {
        title: 'Streamline your warranty process',
        description:
          'Organize your warranties with versioning and set its criteria based on applicable rules like purchase origin or product type. Have the complete overview once claims are requested. ',
        icon: <IconUpsellWarranty />,
        url: '/help/services/warranty',
        bgColor: '#fe871a',
        coverImg: 'warranty-cover.png',
      };
    case 'advanced_components':
      return {
        title: 'Advanced components',
        description:
          'Bring your customers experience to another level with Advanced components, funnel only 5 star product reviews to review platforms or show country specific content.',
        icon: <IconAdvancedComponents />,
        url: '/help/assistant/advanced-components',
        bgColor: '#fe871a',
        coverImg: 'advanced_components-cover.png',
      };
    case 'rules':
      return {
        title: 'Automate your after-sales with Rules',
        description:
          'Create a rule to automate your business processes and make your team more efficient. Set trigger criteria to automatically handle events like new product registration to do actions like sending SMS campaigns. ',
        icon: <IconUpsellArrows />,
        url: '/help/automation/rules',
        bgColor: '#db4dab',
        coverImg: 'rules-cover.png',
      };
    case 'custom_domain':
      return {
        title: 'Make it yours with custom domain',
        description:
          'Showing your company’s domain is critical for brand recognition and customer affinity as you grow your business. Set up your custom domain and build a fully branded digital presence.',
        icon: <IconCustomDomain />,
        url: '/help/my-account/setting-up-a-workspace/custom-domain',
        bgColor: '#140068',
        coverImg: 'custom_domain-cover.png',
      };
    case 'forms':
      return {
        title: 'Capture any customer data with Forms',
        description:
          'Get customer personal information and target product or service upsell opportunities. Forms provide an easy, customizable, and systematized way to gather customer information.',
        icon: <IconUpsellForms />,
        url: '/help/automation/forms',
        bgColor: '#db4dab',
        coverImg: 'forms-cover.png',
      };
    case 'feedback':
      return {
        title: 'Track product & content satisfaction with Feedback',
        description:
          'Improve your content and channel vital product feedback to your hardware teams. Feedback given on the platform is only visible to you and your organization.',
        icon: <IconStar width={'16'} height={'16'} />,
        url: '/help/services/feedback',
        bgColor: '#052bfc',
        coverImg: 'feedback-cover.png',
      };
    case 'api':
      return {
        title: 'Build custom experiences and tools with our API',
        description:
          'Our API enables developers to access workspace data and build custom, unique experiences fast and with scale. Furthermore, you can extract essential data to integrate with your company tools and dashboard.',
        icon: <IconUpsellAPI />,
        url: '/help/connect-tools/for-developers/layerise-api',
        bgColor: '#140068',
        coverImg: 'api-cover.png',
      };
    case 'apps':
      return {
        title: 'Integrate your favorite tools with Apps',
        description:
          'With Layerise apps, you can install and integrate your tools with Layerise. Setup chat widgets like Gorgias or Re:amaze, forward customer data with Zapier, or send chat as tickets to Zendesk.',
        icon: <IconApps />,
        url: '/help/connect-tools/getting-started-connect-tools',
        bgColor: '#140068',
        coverImg: 'apps-cover.png',
      };
    case 'conversations':
      return {
        title: 'Personal & excellent support with Conversations ',
        description:
          'Layerise conversations are built with customer data and after-sales in mind. See what products your customers have and their content engagement, and recommend solutions straight from the interface.',
        icon: <IconChat width={'16'} height={'16'} />,
        url: '/help/services/conversations',
        bgColor: '#2f96e2',
        coverImg: 'conversations-cover.png',
      };
    case 'registrationScore':
      return {
        title: 'Registration Score with industry benchmark ',
        description:
          'The Registration Score is a crucial metric that measures the effectiveness of your customer registration process. It evaluates how easily customers can navigate to the registration process & complete the registration.',
        icon: <IconUpsellRegistrationScore width={'14'} height={'14'} />,
        url: '/help/insights/registration-score',
        bgColor: '#052bfc',
        coverImg: 'registration_score-cover.png',
      };
    case 'purchaseIntentScore':
      return {
        title: 'Optimise marketing spend with Purchase intent ',
        description:
          'With the Purchase Intent Score you can send hot leads straight into your favourite marketing platform and automatically increase the likelihood of re-purchase.',
        icon: <IconGauge />,
        url: '/help/customers/purchase-intent-score',
        bgColor: '#5c3cb1',
        coverImg: 'purchase_intent_score-cover.png',
      };
    case 'serialNumber':
      return {
        title: 'Track product ownership with Serial Numbers ',
        description:
          'With Serial Numbers you can automatically validate product ownership for each customer registration. Bulk upload product serial numbers to Layerise and ensure qualified customer data. ',
        icon: <IconUpsellSerialNumbers />,
        url: '/help/assistant/serial-number',
        bgColor: '#fe871a',
        coverImg: 'serial_number-cover.png',
      };
    case 'custom_font':
      return {
        title: 'Match your brand with Custom fonts',
        description:
          'Improve your appearance to match your brand guidelines with the Custom Fonts addon; you can select between different system fonts, Google fonts, or upload your own custom font.',
        icon: <IconUpsellCustomFont />,
        url: '/help/my-account/setting-up-a-workspace/custom-fonts',
        bgColor: '#140068',
        coverImg: 'custom_font-cover.png',
      };
    case 'diary':
      return {
        title: 'Customer engagement and tracking with Diaries',
        description:
          'With Diary forms you can empower customers to record diaries, provide valuable feedback, and track product interactions, enabling your company to tailor offerings, address concerns, and build lasting brand loyalty.',
        icon: <IconRename />,
        url: '/help/automation/diaries',
        bgColor: '#db4dab',
        coverImg: 'diaries-cover.png',
      };
    case 'lite_users':
      return {
        title: 'Lite user seats',
        description:
          'Elevate your plan with Lite seats, providing specialized access to enhance team productivity. Whether focusing on content, support, or other roles, these seats offer curated permissions for targeted effectiveness.',
        icon: <IconUpsellUsers />,
        url: '/help/my-account/managing-a-workspace/lite-users',
        bgColor: '#0F0055',
        coverImg: 'lite_users-cover.png',
      };
    case 'geo_fencing':
      return {
        title: 'Geo-blocking for Assistants',
        description:
          'Optimize your strategy with geo-blocking for exclusive products, custom assistants, and targeted offerings available only in specific countries and markets. This precision approach maximizes impact and tailors experiences for distinct audiences.',
        icon: <IconLocation />,
        url: '/help/automation/geo-blocking',
        bgColor: '#fe871a',
        coverImg: 'geo_fencing-cover.png',
      };
    default:
      return {
        title: '',
      };
  }
};

export const Upsell = (props: UpsellModalProps) => {
  const handleClick = () => {
    props.onRequestClose();
    setTimeout(() => chatWidget('show'), 0);
  };

  const modalContent = getModalContent(props.variant);

  useEffect(() => {
    window._hsq?.push([
      'trackCustomBehavioralEvent',
      {
        name: process.env.HUBSPOT_UP_SELL_OPPORTUNITY_EVENT,
        properties: {
          [props.variant]: 'true',
        },
      },
    ]);
  }, [props.variant]);

  return (
    <Modal
      onClick={props.onRequestClose}
      modalContainerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 1 }}
      containerStyle={{ zIndex: NORMAL_MODAL_Z_INDEX + 2 }}
      padding="0px 0px 0px 0px"
      height="608px"
      width="520px">
      <ModalHero withBorder>
        <Img
          alt={modalContent.title}
          src={`WWW/featureAddon/${modalContent.coverImg}`}
          height={776}
          width={1040}
          quality={80}
        />
      </ModalHero>
      <Content>
        <TitleContainer>
          <TitleIconContainer bgColor={modalContent.bgColor}>{modalContent.icon}</TitleIconContainer>
          <Title>{modalContent.title}</Title>
        </TitleContainer>
        <Body>{modalContent.description}</Body>
        <ModalActions
          negative={
            <LearnMore href={`${process.env.URL_WWW}${modalContent.url}`} target="_blank" rel="noreferrer">
              Learn more <IconInfo />
            </LearnMore>
          }>
          <Button size="lg" variant="primary" onClick={handleClick}>
            Talk with sales
          </Button>
        </ModalActions>
      </Content>
    </Modal>
  );
};
